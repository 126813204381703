/* You can add global styles to this file, and also import other style files */
$main-blue: #0068af
$light-grey: #eaeaea

:root
    --bs-primary: #0068af

body
    font-family: 'Poppins', sans-serif

h2, .h2
    font-weight: 600
    color: #333 


#cookiePopup 
    display: none
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background-color: rgba(0, 0, 0, 0.85)
    color: white
    padding: 30px
    border-radius: 10px
    width: 90%
    max-width: 400px
    text-align: center
    z-index: 1000
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3)
    

#cookiePopup p 
    margin-bottom: 20px
    font-size: 1.2em
    

#cookiePopup button 
    margin: 10px
    padding: 10px 20px
    background-color: #007bff
    color: white
    border: none
    border-radius: 5px
    cursor: pointer
    font-size: 1em
    

#cookiePopup button:hover 
    background-color: #0056b3
    

#cookiePopup button#declineCookies 
    background-color: #dc3545
    

#cookiePopup button#declineCookies:hover 
    background-color: #c82333
    

.subtitle
    color: #555
    margin-bottom: 3rem

.subtitle-section
    font-size: 20px
    font-weight: 300

.subtitle-blue
    color: $main-blue
    font-size: 1.1rem
    text-transform: uppercase
    font-weight: 500
    letter-spacing: 0.1rem

#mainContent
    min-height: 50vh
    margin-top: 4rem
    margin-bottom: 2rem

@media (max-width: 991px)
    #mainContent
        margin-top: 5rem

#mainContent h2
    margin-top: 2rem
    margin-bottom: 1rem

#mainContent .servicios p
    margin-bottom: 1.5rem

.color-blue
    color: $main-blue

a
    color: #0069b4

figcaption
    font-size: 13px
    font-style: italic
    color: #555 

.card
    border: none
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px

.card-title, .card-title a
    color: #0069b4
    text-decoration: none

.nav-item.active
    background-color: transparent !important

.card.blog
    box-shadow: none
    cursor: pointer

.card.blog .card-body
    padding-right: 0
    padding-left: 0

.card.blog .card-img-top
    border-radius: 0
    object-fit: cover
    object-position: center
    height: 260px

.btn
    border-radius: 0

.btn-primary
    --bs-btn-bg: #0068af
    --bs-btn-border-color: #0068af
    --bs-btn-hover-color: #fff
    --bs-btn-hover-bg: #024a7c
    --bs-btn-hover-border-color: #024a7c
    --bs-btn-active-bg: #024a7c
    --bs-btn-active-border-color: #024a7c
    --bs-btn-disabled-bg: #0068af
    --bs-btn-disabled-border-color: #0068af

.btn-secondary
    --bs-btn-color: #222
    --bs-btn-bg: #eaeaea
    --bs-btn-border-color: #eaeaea
    --bs-btn-hover-color: #222
    --bs-btn-hover-bg: #d9d9d9
    --bs-btn-hover-border-color: #d9d9d9
    --bs-btn-active-bg: #d9d9d9
    --bs-btn-active-border-color: #d9d9d9
    --bs-btn-disabled-bg: #eaeaea
    --bs-btn-disabled-border-color: #eaeaea


.paginado .btn
    width: 35px
    height: 35px

.paginado .btn:not(:last-child)
    margin-right: 5px


/* Sistema de grid tipo bootstrap */
.grid-container
    display: grid
    gap: 16px

.grid-sm-1
    grid-template-columns: repeat(1, 1fr)
.grid-sm-2
    grid-template-columns: repeat(2, 1fr)
.grid-sm-3
    grid-template-columns: repeat(3, 1fr)
.grid-sm-4
    grid-template-columns: repeat(4, 1fr)
.grid-sm-5
    grid-template-columns: repeat(5, 1fr)
.grid-sm-6
    grid-template-columns: repeat(6, 1fr)

@media (min-width: 768px)
    .grid-md-1
        grid-template-columns: repeat(1, 1fr)
    .grid-md-2
        grid-template-columns: repeat(2, 1fr)
    .grid-md-3
        grid-template-columns: repeat(3, 1fr)
    .grid-md-4
        grid-template-columns: repeat(4, 1fr)
    .grid-md-5
        grid-template-columns: repeat(5, 1fr)
    .grid-md-6
        grid-template-columns: repeat(6, 1fr)

@media (min-width: 992px)
    .grid-lg-1
        grid-template-columns: repeat(1, 1fr)
    .grid-lg-2
        grid-template-columns: repeat(2, 1fr)
    .grid-lg-3
        grid-template-columns: repeat(3, 1fr)
    .grid-lg-4
        grid-template-columns: repeat(4, 1fr)
    .grid-lg-5
        grid-template-columns: repeat(5, 1fr)
    .grid-lg-6
        grid-template-columns: repeat(6, 1fr)

@media (min-width: 1200px)
    .grid-xl-1
        grid-template-columns: repeat(1, 1fr)
    .grid-xl-2
        grid-template-columns: repeat(2, 1fr)
    .grid-xl-3
        grid-template-columns: repeat(3, 1fr)
    .grid-xl-4
        grid-template-columns: repeat(4, 1fr)
    .grid-xl-5
        grid-template-columns: repeat(5, 1fr)
    .grid-xl-6
        grid-template-columns: repeat(6, 1fr)